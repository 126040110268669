// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--QHcqi {
  padding: 40px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.s-module__wrap--QHcqi h1 {
  font-size: 30px;
  line-height: 36px;
  font-weight: bolder;
}
.s-module__wrap--QHcqi .s-module__des--x5ayv {
  font-size: 16px;
  line-height: 24px;
  color: #6B7280;
  margin-top: 16px;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 24px;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC .s-module__left--ocKn5 {
  width: 60%;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC .s-module__left--ocKn5 .s-module__item--yk7hB {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 36px;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC .s-module__left--ocKn5 .s-module__item--yk7hB img {
  margin-right: 24px;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC .s-module__left--ocKn5 .s-module__item--yk7hB .s-module__itemTitle--P0wZh {
  font-size: 18px;
  line-height: 26px;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC .s-module__left--ocKn5 .s-module__item--yk7hB .s-module__itemContent--jdbMt {
  font-size: 16px;
  line-height: 20px;
  color: #6B7280;
  margin-top: 8px;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC .s-module__right--ijyJ2 {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC .s-module__right--ijyJ2 img {
  max-width: 90%;
  max-height: 300px;
}
.s-module__wrap--QHcqi .s-module__content--uB_gC .s-module__footer--yeKUG {
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ChannelLandingPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AANA;EAQI,eAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AAXA;EAcI,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAjBA;EAqBI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAvBA;EA2BM,UAAA;AADN;AA1BA;EA8BQ,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AADR;AAhCA;EAoCU,kBAAA;AADV;AAnCA;EAwCU,eAAA;EACA,iBAAA;AAFV;AAvCA;EA6CU,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AAHV;AA7CA;EAsDM,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AANN;AAnDA;EA4DQ,cAAA;EACA,iBAAA;AANR;AAvDA;EAkEM,mBAAA;AARN","sourcesContent":[".wrap {\n  padding: 40px;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  gap: 48px;\n\n  h1 {\n    font-size: 30px;\n    line-height: 36px;\n    font-weight: bolder;\n  }\n\n  .des {\n    font-size: 16px;\n    line-height: 24px;\n    color: #6B7280;\n    margin-top: 16px;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: row;\n    margin-top: 24px;\n    margin-bottom: 24px;\n\n    .left {\n      width: 60%;\n\n      .item {\n        display: flex;\n        flex-direction: row;\n        align-items: flex-start;\n        margin-bottom: 36px;\n\n        img {\n          margin-right: 24px;\n        }\n\n        .itemTitle {\n          font-size: 18px;\n          line-height: 26px;\n        }\n\n        .itemContent {\n          font-size: 16px;\n          line-height: 20px;\n          color: #6B7280;\n          margin-top: 8px;\n        }\n      }\n    }\n\n    .right {\n      width: 40%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n\n      img {\n        max-width: 90%;\n        max-height: 300px;\n      }\n    }\n\n    .footer {\n      margin-bottom: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--QHcqi`,
	"des": `s-module__des--x5ayv`,
	"content": `s-module__content--uB_gC`,
	"left": `s-module__left--ocKn5`,
	"item": `s-module__item--yk7hB`,
	"itemTitle": `s-module__itemTitle--P0wZh`,
	"itemContent": `s-module__itemContent--jdbMt`,
	"right": `s-module__right--ijyJ2`,
	"footer": `s-module__footer--yeKUG`
};
export default ___CSS_LOADER_EXPORT___;
