import { message, Spin, Tabs } from 'antd';
import React, { useCallback, useEffect, createContext, useState, useMemo } from 'react';
import type { TabsProps } from 'antd';
import ChannelStorePage from './components/ChannelStorePage';
import MyChannel from './components/MyChannel';
import s from './s.module.less';
import { isFreeUser, shouldShowKlarityPlanUI } from 'utils/provider';
import SubscriptionStore from 'store/Subscription';
import { Channel } from 'types/common';
import LandingPage from 'pages/dashboard/ChannelLandingPage';
import useChannelHomeInfo from 'hooks/useChannelHomeInfo';
import providerStatusStore from 'store/ProviderStatus';
import { getSkipChannelCare } from 'utils/sessionStorage';
import { getUrlParam } from 'utils/common';
import { MY_CHANNELS } from 'constants/common';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { TSubscriptionType } from 'types/subscription';
import KlarityChannelStorePage from './components/KlarityChannelStorePage';
import homeStore from 'store/Home';
import KlarityMyChannel from './components/KlarityMyChannel';
import { getHideChannelManagementDelegationForever } from 'utils/localstore';
import { getConnectChannelList, getShouldShowCMDNotice } from 'api/channel';
import ChannelStore from 'store/Channel';
import ChannelContext from './context';

const ChannelMainPage = () => {
    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlan = getSubscription('currentPlan');
    const isFree = isFreeUser();
    const [, setLiveChannelList] = useState<Channel[]>([]);
    const [info = undefined] = useChannelHomeInfo();
    const [, setProviderStatus] = providerStatusStore.useStore();
    const skip = getSkipChannelCare();
    const [tabKey, setTabKey] = useState('1');
    const type = getUrlParam('type');
    const [getHomeStore] = homeStore.useStore();
    const homeInfo = getHomeStore('data');
    const isKlarity = shouldShowKlarityPlanUI(homeInfo);
    const showKlarityPlanLandingPage = shouldShowKlarityPlanUI(homeInfo);
    const finishedKlaritySetUp = !!homeInfo?.klarityStarterSetUpCompleted;
    const [loading, setLoading] = useState(true);
    // const [channelList, setChannelList] = useState([]);
    const [, setChannelStore] = ChannelStore.useStore();
    const [showChannelManagementDelegationNotice, setShowChannelManagementDelegationNotice] = useState(false);

    const fetChannels = useCallback(async () => {
        setLoading(true);

        try {
            const res = await getConnectChannelList();

            if (res && !res.error) {
                setChannelStore('collectedChannels', res?.data?.data || []);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    const checkBackendShowChannelManagementDelegationNotice = useCallback(async () => {
        if (!isFree && !isKlarity) {
            if (getHideChannelManagementDelegationForever()) {
                setShowChannelManagementDelegationNotice(false);
            } else {
                setLoading(true);
                try {
                    const res = await getShouldShowCMDNotice();

                    if (res && !res.error) {
                        setShowChannelManagementDelegationNotice(!!res?.data?.data);
                    } else {
                        message.error(res?.error);
                    }
                } catch (e) {
                    console.error(e);
                }
                setLoading(false);
            }
        }
    }, [isFree, isKlarity]);

    useEffect(() => {
        checkBackendShowChannelManagementDelegationNotice();
    }, [checkBackendShowChannelManagementDelegationNotice]);

    useEffect(() => {
        fetChannels();
    }, [fetChannels]);

    useEffect(() => {
        if (info) {
            setProviderStatus('data', info);
        }
    }, [info]);

    useEffect(() => {
        if (type === MY_CHANNELS) {
            setTabKey('2');
        } else {
            setTabKey('1');
        }
    }, [type]);

    const hideChannelManagementDelegationNotice = useCallback(() => {
        checkBackendShowChannelManagementDelegationNotice();
    }, [checkBackendShowChannelManagementDelegationNotice]);

    const handleTabClick = useCallback((key: string) => {
        setTabKey(key);
    }, []);

    const handleSetLiveChannelData = useCallback((data: Channel[]) => {
        setLiveChannelList(data);
    }, []);

    const switchToMychannel = () => {
        setTabKey('2');
    };

    const contextValue = useMemo(() => ({
        hideChannelManagementDelegationNotice,
    }), [hideChannelManagementDelegationNotice]);

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: 'Channel Store',
            children: isKlarity ? <KlarityChannelStorePage switchToMychannel={switchToMychannel} setLiveChannelData={handleSetLiveChannelData} /> : <ChannelStorePage showChannelManagementDelegationNotice={showChannelManagementDelegationNotice} switchToMychannel={switchToMychannel} isFree={isFree} setLiveChannelData={handleSetLiveChannelData} />,
        },
        {
            key: '2',
            label: 'My channels',
            children: isKlarity ? <KlarityMyChannel setTabKey={setTabKey} isFree={isFree} currentPlan={currentPlan} /> : <MyChannel showChannelManagementDelegationNotice={showChannelManagementDelegationNotice} setTabKey={setTabKey} isFree={isFree} currentPlan={currentPlan} />,
        },
    ];

    if (!info) {
        return <Spin spinning><div style={{ height: '200px' }} /></Spin>;
    }

    if (showKlarityPlanLandingPage) {
        if (!finishedKlaritySetUp) {
            return <LandingPage />;
        }
    } else if (!info?.channelGuide && !skip) {
        return <LandingPage />;
    }

    return (
        <ChannelContext.Provider value={contextValue}>
            <div className={s.wrap}>
                <Spin spinning={loading}>
                    <div className={s.header}>
                        <div className={s.inner}>Channel</div>
                    </div>
                    <div className={s.content}>
                        <Tabs activeKey={tabKey} items={tabItems} onTabClick={handleTabClick} />
                    </div>
                </Spin>
            </div>
        </ChannelContext.Provider>
    );
};

export default ChannelMainPage;
